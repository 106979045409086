import React, { useEffect, useState } from 'react';
import './Main.scss';
import { Link } from 'react-router-dom';
import { useStore } from '../utils/Store';
import * as Icon from 'react-feather';
import BrandLogo from '../assets/brand-logo.png';
const navList = [
    {
        url: '/',
        label: 'Home',
        id: 'home'
    },
    {
        url: '/games',
        label: 'Games',
        id: 'games'
    },
    {
        url: '/events',
        label: 'Events',
        id: 'events'
    },
    {
        url: '/videos',
        label: 'Videos',
        id: 'videos'
    },
    {
        url: '/about',
        label: 'About',
        id: 'about'
    }
];
const Main = {
    SideBar: () => {
        const [store] = useStore();
        const [showMenu, setMenu] = useState(false);

        useEffect(() => {
            setMenu(false);
        }, [store.activePage]);

        const menu_items = navList;

        function navigateTo(path) {
            window.location.href = path;
        }

        function toggleMobMenu() {
            setMenu(!showMenu);
        }

        const view = <div className="sidebar_main slideInLeft animated">
            <div className="brand_name">
                <Link to='/'><div><img src={BrandLogo} alt="kerala net ball team" /></div></Link>
                <Link to='/'> <div className="label">
                    Kerala<br />
                    Netball<br />
                    Association
                </div>
                </Link>
            </div>
            <div className="nav-wrap">
                <div className="main_menu">
                    {
                        menu_items.map((item) =>
                            <Link key={item.id} to={item.url}><div className={'item ' + (store.activePage === item.id ? 'active' : '')}><label>{item.label}</label> <Icon.ArrowDown className="icon" width="30px" /></div></Link>
                            // <div className={'item ' + (store.activePage === item.id ? 'active' : '')} onClick={() => navigateTo(item.url)}><label>{item.label}</label> <Icon.ArrowRight className="icon" width="70px" /></div>
                        )
                    }

                </div>
            </div>
            <div className="mob-trigger">
                <div className="wrapper" onClick={toggleMobMenu}>
                    <Icon.Menu />
                </div>
            </div>
            {showMenu ? <div className="mobile-menu-container">
                {
                    menu_items.map((item) =>
                        <div className="mobile-menu-item">
                            <Link key={item.id} to={item.url}>
                                <div className={'item ' + (store.activePage === item.id ? 'active' : '')}><label>{item.label}</label></div>
                            </Link>
                        </div>
                    )
                }
                <div className="close-menu" onClick={toggleMobMenu}>
                    <Icon.X className="icon" width="30px" />
                </div>
            </div> : ''}
        </div>
        return view;
    },
    Footer: () => {
        const view = (<div className="footer-container">
            <div className="wrapper">
                <div className="brand-name">
                    <Link to='/'><div><img src={BrandLogo} alt="kerala net ball team" /></div></Link>
                    <div className="label">
                        Kerala Netball Association is for players of all shapes, sizes, and standard, providing every member opportunities to learn and develop.
                    </div>
                </div>
                <div className="footer-contents">
                    <div className="nav-list">
                        {
                            navList.map(item => <div className="item" key={item.id}>
                                <Link to={item.url}>{item.label}</Link></div>)
                        }

                    </div>
                </div>
                <div className="footer-contact">
                    <div className="name">Contact Us</div>
                    <div className="li"><Icon.PhoneCall /> +91 9447578522</div>
                    <div className="li"><Icon.MessageCircle /> keralanetballassociation@gmail.com</div>
                </div>
                <div className="footer-about">
                    <div className="name">About Us</div>
                    <div className="li">Kerala Netball Association is the sole, state recognised, governing body for Netball, affiliated to the Kerala State Govt,</div>
                </div>
            </div>
            <div className="footer-rights">(c) Kerala Netball Association</div>
        </div>);
        return view;
    }
}


export default Main;