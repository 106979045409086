import React, { useEffect, useState } from 'react';
import { useStore } from '../utils/Store';
import './Events.scss';
import Axios from 'axios';
import api from '../utils/Api';

const EventsPage = {
    Index: () => {
        const [store, setStore] = useStore();
        const [categoryList, setCat] = useState([]);
        const [eventsList, setEvents] = useState([]);
        const [currentCat, setCurrentCat] = useState('');
        const [gallery, setGallery] = useState([]);

        useEffect(() => {
            setStore({ ...store, activePage: 'events' });
            Axios.get(api.ALL_CATEGORY)
                .then(res => {
                    if (res.data.success) {
                        const list = res.data.data;
                        setCat(list);
                        if (list.length) {
                            setCurrentCat(list[0].id)
                        }
                    }
                });
        }, []);

        useEffect(() => {
            if (!currentCat) {
                return;
            }
            setEvents([]);
            setGallery([]);
            Axios.get(api.PRD_CAT + currentCat)
                .then(res => {
                    if (res.data.success) {
                        setEvents(res.data.data);
                    }
                });

            Axios.get(api.ALL_GALLERY + currentCat)
                .then(res => {
                    if (res.data.success) {
                        setGallery(res.data.data);
                    }
                }, err => {

                })

        }, [currentCat]);

        const sections = [{ id: 'NL', title: 'National Level' }, { id: 'SL', title: 'State Level' }];

        function loadCat(id) {
            setCurrentCat(id)
        }

        const timeline = (
            <div className="timeline-container">
                <div className="wrapper scroller">
                    <div className="list-time">
                        {
                            categoryList.map(item => <div className={'item ' + (currentCat === item.id ? 'active' : '')} onClick={() => loadCat(item.id)}>{item.title}</div>)
                        }

                    </div>
                    <div className="page-name"><div>EVENTS</div></div>
                </div>
            </div>);

        const view = (category) => (
            <div className="events-container">
                <div className="wrapper">
                    <div className="page-header">
                        <div className="page-title">Highlights</div>
                        <div className="splitter"><div></div></div>
                        <div className="page-sub">{category.title}</div>
                    </div>
                    <div className="events-list">
                        {
                            eventsList.map((item, key) => item.subcat === category.id ? (
                                <div className="item-wrapper" key={key}>
                                    <div className="bg-layer make-animated slideInRight">
                                        <img src={item.image} alt={item.title} />
                                    </div>
                                    <div className="ev-date">{item.description2}</div>
                                    <div className="ev-name">{item.title}</div>
                                </div>
                            ) : '')
                        }{
                            eventsList.length === 0 ? 'Sorry, no events found on this date' : ''
                        }
                    </div>
                </div>
            </div>
        );

        return <>{timeline}{
            sections.map(item => view(item))
        }
            <div className="events-container">
                <div className="wrapper">
                    <div className="page-header">
                        <div className="page-title">Gallery</div>
                    </div>
                    <div className="events-gallery">
                        {
                            gallery.map((item, key) => <div className="events-gallery-thumb" key={key}>
                                <img src={item.url} alt="event" />
                            </div>)}
                    </div>
                </div>
            </div>
        </>;
    }
}

export default EventsPage;