import React, { useEffect, useState } from 'react';
import './About.scss';
import { useStore } from '../utils/Store';
import * as Icon from 'react-feather';
import HistoryImg from '../assets/extras/history.jpg';

const About = {
    Index: () => {
        const [store, setStore] = useStore();

        useEffect(() => {
            setStore({ ...store, activePage: 'about' })
        }, []);


        const view = (
            <div className="about-container">

                <div className="about-body">
                    <div className="page-title">
                        <Icon.AtSign className="ico" /> About
                    </div>
                    <div className="contents">
                        <div className="title">Over 20 million people play Netball around the world in more than 117 countries.</div>
                        <div className="para">Kerala Netball Association is recognised by Kerala State Sports Council and registered under Netball Federation of India. It was formed in 2015. After that Netball in kerala has grown immensely. Kerala men team has won Bronze medal in 35th National Games held in Kerala. In 2015 itself Kerala men team won Bronze medal in Senior Nationals. This was a great achievement. After that we have won Gold, Silver and bronze medal in Sub-Junior, Junior and Senior categories in different championships. In 2019 India won Silver medal in U-16 South Asian Netball Championship. Three players in that team including the Vice captain was from Kerala. Their contribution was  tremendous.</div>
                        <div className="para">
                            When we speak about the Championship's in Kerala, in every state championship all 14 districts has been participating. We are a team and we work for the upliftment of the Netball Players in our state.
                        </div>
                        <div className="para">
                            And at this pandemic situation also our player had put a great effort and won bronze medal in Junior catogery (2020-21).
                        </div>
                        <div className="quote">
                            <div className="img"><Icon.MessageCircle className="ico" /></div>
                            <div className="text">Check out a few more great camis under $100 below. Even as an adult I still get excited for new sportswear pieces.</div>
                            <div className="author">— Timothy Scorsese</div>
                        </div>

                        <div className="para">Currently, 76 National Netball Associations are affiliated to World Netball grouped into 5 Regions – Africa, Asia, Americas, Europe and Oceania – each with a respective Regional Federation. Regional Federations are an integral part of the global governance structure and assist in the implementation of World Netball policies and the development of the sport in their respective regions.</div>
                    </div>
                </div>

                <About.History />
                <About.Contact />
            </div>
        );
        return view;
    },
    History: () => {
        const [showContent, setContent] = useState(false);
        function showAllHistory() {
            setContent(true);
        }

        const view = (

            <div className="about-body">
                <div className="page-title">
                    <Icon.AtSign className="ico" /> History of Netball
                </div>
                <div className="history-body">
                    <div className={'history-body-content ' + (showContent ? 'show' : '')}>
                        <p> In 1891 in Springfield, Massachusetts a 30-year-old Canadian immigrant to the USA, James Naismith, was ordered to invent an indoor game for high-spirited young men at the School for Christian Workers (later the YMCA).</p>

                        <p>Most games tried ended with injury rates of staggering proportions! So Naismith conjured up a game whereby a ball had to be lobbed into a high peach basket (his reasoning being that if a ball had to dropped into the “goal”, it couldn’t be thrown at breakneck speed).</p>

                        <p>Basketball was born, with the original game featuring nine players – three forwards, three centres and three guards – simply because Naismith had 18 youths to keep amused.</p>

                        <p>Women’s indoor basketball began exactly two days later when female teachers to the gym were captivated by the game but it wasn’t until 1895 that the current game of netball was well and truly shaped.When Clara Baer, a sports teacher in New Orleans, wrote to Naismith asking for a copy of the rules, the subsequent rules package contained a drawing of the court with lines penciled across it, simply to show the areas various players could best patrol.But Baer misinterpreted the lines and thought players couldn’t leave those areas! In 1899 her mistake was ratified into the rules of women’s basketball as zones.</p >

                        <p>Three - bounce dribbling had quickly been extended in the men’s game(which didn’t have no - go zones), but it was seldom used in the women’s version when it reached Britain and the Empire.In fact, there was no pressure to increase that form of ball movement and in the end dribbling simply ceased to exist.</p >

                        <p>netball -in -the - playgroundNetball was first played in England in 1895 at Madame Ostenburg’s College.In the first half of the 20th century, Netball’s popularity continued to grow, with the game being played in many British Commonwealth countries.There were no standard rules at that time with both nine - a - side and five - a - side versions of the game.</p >

                        <p>During an Australian tour of England in 1957, discussions took place concerning standardising the rules of the sport and this led to representatives from England, Australia, New Zealand, South Africa and The West Indies meeting in Sri Lanka in 1960, to establish The International Federation of Women’s Basketball and Netball.Formal rules were established at this inaugural meeting and it was decided to hold World Championship tournaments every four years, beginning in Eastbourne, England, in 1963.</p >

                        <p>Since then World Championships have been held in Australia 1967, Jamaica 1971, New Zealand 1975, Trinidad & Tobago 1979, Singapore 1983, Scotland 1987, Australia 1991, England 1995 and New Zealand 1999. Throughout this period, Australia has dominated, winning the event in 1971, 1975, 1979, 1983, 1991, 1995 and 1999. The 2003 World Netball Championships in Kingston, Jamaica saw New Zealand finally breaking the Australian dominance taking Gold.The 2007 World Netball Championships was due to take place in Fiji but a political coup in the country led to the event being transferred to Auckland, New Zealand.Despite the home advantage, New Zealand were unable to defend their crown and Australia were once more World Champions.Australia retained their title in 2011 in Singapore and in 2015 in Sydney.</p >

                        <p>As part of the Australian Bicentenary Celebrations in 1988, a Youth Tournament took place in Canberra, for players aged under 21. Its success led to this event being held once every four years.Fiji hosted the 2nd World Youth Netball Championship, Canada the 3rd and the 4th has just taken place in Wales.Australia were winners in 1988, New Zealand in 1992 and Australia again in 1996 and 2000. At the 2005 World Youth Netball Championship, held in Florida New Zealand Won Gold, England Silver and Australia took the Bronze.</p >

                        <p>In 1995 Netball became a “recognized” sport of the championsInternational Olympic Committee(IOC) and one of the Federation’s objectives is to ensure this status is retained and to encourage the International Olympic Committee to include Netball in the Olympic Games Programme in the future.</p >

                        <p>Netball was included in the Commonwealth Games programme, for the first time, in 1998 in Kuala Lumpur, where Australia took the Gold medal, New Zealand Silver and England the Bronze. It was also a programmed sport in 2002 Commonwealth Games in Manchester (England), where Australia again took the Gold medal, New Zealand Silver and Jamaica edging out England for the Bronze. In 2006 (Melbourne, Australia) and 2010 (Delhi, India) New Zealand beat Australia and England won the bronze medal. Netball is now a core sport in the Commonwealth Games, with the next editions taking place in The Gold Coast, 2018.</p>

                        <p>2008 saw the launch of Fast Net World Netball Series, a shorter, sharper version of the game which in 2012 evolved into Fast5, attracting new audiences around the globe.</p>

                        <p>2012 also saw the sports governing body unveil a new logo and renamed itself as the International Netball Federation (INF). We hope that you will join us on Netball’s journey to the future.</p>

                        <div className="read-more" onClick={showAllHistory}>Read More...</div>
                    </div>
                    <div className="img-wrapper">
                        <img src={HistoryImg} />
                    </div>
                </div>
            </div>
        );
        return view;
    },
    Contact: () => {
        const view = (

            <div className="about-body">
                <div className="page-title">
                    <Icon.AtSign className="ico" /> Contact Us
                </div>
                <div className="contents">
                    <div className="title" style={{ marginBottom: '10px' }}><Icon.PhoneCall /> <span className="para">+91 9447578522</span></div>
                    <div className="title" style={{ marginBottom: '10px' }}><Icon.MessageCircle /> <span className="para">keralanetballassociation@gmail.com</span></div>
                    <div className="title"><Icon.Mail /> <span className="para">Senate, Kannyakulangara, Vembayam P.O, Theakada, Thiruvananthapuram</span></div>
                </div>
            </div>
        );
        return view;
    }
}

export default About;