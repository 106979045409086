import React, { useEffect } from 'react';
import './Games.scss';
import * as Icon from 'react-feather';
import { useStore } from '../utils/Store';
import api from '../utils/Api';

import rulebookBanner from '../assets/extras/rulebook-banner.jpg';

const Games = {
    Index: () => {
        const [store, setStore] = useStore();

        useEffect(() => {
            setStore({ ...store, activePage: 'games' });

        }, []);

        const featuredList = [{
            name: 'Team Registration Form',
            url: '/documents/Team-Registration-Form.pdf'
        },
        {
            name: 'Team Entry Form',
            url: '/documents/Team-Registration-Form.pdf'
        },
        {
            name: 'Team - Photo - Form',
            url: '/documents/Team-Registration-Form.pdf'
        }, {
            name: 'Individual-Eligibility',
            url: '/documents/Team-Registration-Form.pdf'
        }];

        const otherList = [
            {
                name: 'Match-Result',
                url: '/documents/Team-Registration-Form.pdf'
            },
            {
                name: 'Match - Scoresheet',
                url: '/documents/Team-Registration-Form.pdf'
            },
            {
                name: 'Match - Substitution',
                url: '/documents/Team-Registration-Form.pdf'
            },
            {
                name: 'Match-Team-List',
                url: '/documents/Team-Registration-Form.pdf'
            }];

        function downloadAsset(file) {
            window.open(file)
        }

        const timeline = (
            <div className="timeline-container">
                <div className="wrapper">
                    <div></div>
                    <div className="page-name"><div>GAMES</div></div>
                </div>
            </div>);

        const gamesList = <div className="games-container">
            <div className="wrapper">
                <div className="page-header">
                    <div className="page-title">International Rule Book</div>
                </div>
                <div className="rulebook-body">
                    <img src={rulebookBanner} />
                    <div className="rulebook-body-text"><a href="https://drive.google.com/file/d/14E9H2xr69LZA7W0pmYPq48sb5qzhTHqk/view" target="_BLANK">Download Rulebook</a>
                        <div className="sub-text">THE RULES OF NETBALL</div>
                    </div>
                </div>

                <div className="page-header">
                    <div className="page-title">Team Registration Forms</div>
                </div>
                <div className="featured-list">
                    {
                        featuredList.map(item => <div className="card" onClick={() => downloadAsset(item.url)}>
                            <div className="icon"><Icon.FileText /></div>
                            <div className="title">{item.name}</div>

                            <div className="download"><Icon.Download /></div>
                        </div>)
                    }
                </div>
                <div className="page-header">
                    <div className="page-title">Match Documents</div>
                </div>

                <div className="other-list">
                    {
                        otherList.map(item => <div className="item" onClick={() => downloadAsset(item.url)}>
                            <div className="icon"><Icon.FileText /></div>
                            <div className="title">{item.name}</div>
                            <div className="download"><Icon.Download /></div>
                        </div>)
                    }
                </div>
            </div>
        </div>;
        return <>{timeline}{gamesList}</>;
    }
}

export default Games;