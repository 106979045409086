import React, { useEffect, useState } from 'react';
import { useStore } from '../utils/Store';
import * as Icon from 'react-feather';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Ticker, { NewsTicker } from 'nice-react-ticker';

import Banner2Img from '../assets/banner/b02.jpg';
import { Link } from 'react-router-dom';
import api from '../utils/Api';
import Axios from 'axios';

const Home = {
    Index: () => {
        const [carouselSet, setCarousel] = useState([]);
        const [upcomingEvents, setEvents] = useState([]);
        const [store, setStore] = useStore();

        useEffect(() => {
            setStore({ ...store, activePage: 'home' });

            Axios.get(api.HOME_GALLERY)
                .then(res => {
                    if (res.data.success) {
                        setCarousel(res.data.data);
                    }
                }, err => {

                });

            Axios.get(api.UPCOMING_EVENTS).then(res => {
                if (res.data.success) {
                    setEvents(res.data.data);
                    if (res.data.data.length < 5) {
                        let arr = [];
                        for (let i = 0; i < 5; i++) {
                            arr = arr.concat(res.data.data);
                        }

                        setEvents(arr);
                    }
                }
            });



        }, []);


        const eventList = [{
            id: 'au',
            label: 'Australia',
        }, {
            id: 'nz',
            label: 'New Zealand',
        },
        {
            id: 'en',
            label: 'England',
        }, {
            id: 'ja',
            label: 'Jamaica',
        }, {
            id: 'sa',
            label: 'South Africa',
        }, {
            id: 'ma',
            label: 'Malawi',
        }, {
            id: 'ug',
            label: 'Uganda',
        }, {
            id: 'sl',
            label: 'Scotland',
        }, {
            id: 'wa',
            label: 'Wales',
        }, {
            id: 'tt',
            label: 'Trinidad & Tobago'
        }]


        const jumbotron = <div className="home-main-jumbotron">
            <div className="main-text">
                <div className="wrapper">
                    <div className="title">LOOK UP, GET UP</div>
                    <div className="title">& NEVER GIVE UP</div>
                    <div className="cta">
                        <Link to="/events">
                            <button>View Events</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="bg-1"></div>
        </div>;
        const signup = <div className="membership-container">
            <div className="wrapper">
                <div className="label">District Association</div>
                <div>
                    <a href="http://admin.keralanetball.in">
                        <button className="primary">Login</button>
                    </a>
                </div>
            </div>
            <div className="newsticker">
                <Ticker isNewsTicker={true}>
                    {upcomingEvents.map((item, i) => <NewsTicker key={i} id={i} title={item.title} />
                    )}
                </Ticker>
            </div>
        </div>
        const view = <div className="home_container">
            <div className="home-contents">
                <div className="bg-layer fadeIn make-animated">
                    <div className="home-jumbotron">
                        <div className="title">Founded in 2015, Association has done a great things to uplift the netball players even into international level.</div>
                        <div className="home-rulebook">
                            <a href="https://drive.google.com/file/d/14E9H2xr69LZA7W0pmYPq48sb5qzhTHqk/view" target="_BLANK">Download Rulebook</a>
                            <div className="more">THE RULES OF NETBALL</div>
                        </div>
                    </div>
                    <div className="home-banner fadeIn make-animated">
                        <div className="header">Discover.</div>
                        <Carousel showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true}>
                            {
                                carouselSet.map((item, key) => <div key={key} className="slider-img">
                                    <img src={item.url} alt="handball player" />
                                </div>)
                            }
                        </Carousel>
                        <div className="home-facilities">
                            <div>FACILITIES AND EQUIPMENT</div>
                            <div className="more"><Link to="/facilities">Learn more</Link></div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="home-video-preview">
                <div className="bg-layer slideInDown make-animated">
                    <img src={Banner2Img} alt="video"></img>
                    <div className="video-label">
                        <Link to="/videos"><Icon.PlayCircle style={{ color: '#fff' }} /></Link>
                        <div className="label">Almost before we knew it, we had left the ground.</div>
                    </div>
                    <div className="overlay"></div>
                </div>
            </div>
            <div className="home-events-preview">
                <div className="bg-layer slideInUp make-animated">
                    <div className="header">
                        <div className="h-label">World Ranking</div>
                        <div className="controls">
                            <div className="btn-nav">
                                <Icon.ChevronLeft width="50px" />
                            </div>
                            <div className="btn-nav">
                                <Icon.ChevronRight width="50px" />
                            </div>
                        </div>
                    </div>
                    <div className="event-data">
                        {eventList.map((item, i) => <div className="e-date" key={i}>
                            <div className="position">{i + 1}</div>
                            <div className={'cn-icon ' + (item.id)}></div>
                            <span>{item.label}</span>
                        </div>)}
                    </div>
                </div>
            </div>
        </div >

        return <div>{jumbotron}{signup}{view}<Home.RecruitBanner /></div>;
    },
    RecruitBanner: () => {
        const year = new Date();

        const view = <div className="home-recruit-banner">
            <div className="wrapper">
                <div className="text">
                    <div className="a">Season</div>
                    <div className="b">{year.getFullYear()}</div>
                    <div className="c">We<br />Create</div>
                    <div className="d">New Stars</div>
                </div>
            </div>
        </div>

        const features = <div className="recruit-features">
            <div className="item">
                <div className="icon"><Icon.Award /></div>
                <div className="title">Our Goal</div>
                <div className="para">We believe that netball should become No. 1 sport among youngsters and adults.</div>
            </div>
            <div className="item">
                <div className="icon"><Icon.UserPlus /></div>
                <div className="title">Qualified Coaches</div>
                <div className="para">Our association has a team of qualified netball coaches that take care of your success.</div>
            </div>
            <div className="item">
                <div className="icon"><Icon.Star /></div>
                <div className="title">Great Teamwork</div>
                <div className="para">Teamwork is one of the main principles that we pursue at our association.</div>
            </div>
        </div>

        return <div>{view} {features}</div>;
    }
}


export default Home;