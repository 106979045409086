import React from 'react';
import './App.scss';
import './layout/Animate.scss';

import Main from './layout/Main';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { Container } from 'tabler-react';
import { Routes } from './Router';


function App() {
  const view = (
    <Router>
      <div id="main-body" className="site-wrapper">
        <Main.SideBar />
        <div className="main-body-contents">
          <Routes />
        </div>
        <Main.Footer />
      </div>
    </Router>
  );

  return view;
}
export default App;
