import settings from '../app.config';

const api = {
    BASE: settings.server,
    ALL_CATEGORY: settings.server + '/category/all',
    PRD_CAT: settings.server + '/category/',
    ALL_VIDEOS: settings.server + '/products/subcat/video',
    HOME_GALLERY: settings.server + '/gallery/home',
    ALL_GALLERY: settings.server + '/gallery/',
    UPCOMING_EVENTS: settings.server + '/events/upcoming'
}

export default api;