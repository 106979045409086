import React, { useState, useEffect } from 'react';
import * as Icons from 'react-feather';
import './Videos.scss';
import api from '../utils/Api';
import Axios from 'axios';
import { useStore } from '../utils/Store';


const Videos = {
    Index: () => {
        const [store, setStore] = useStore();
        const [videos, setList] = useState([]);
        const [activeVideo, setVideoActive] = useState({});

        useEffect(() => {
            setStore({ ...store, activePage: 'videos' });
            Axios.get(api.ALL_VIDEOS)
                .then(res => {
                    if (res.data.success) {
                        const list = res.data.data
                        setList(list);
                        if (list.length) {
                            setVideo(list[0]);
                        }
                    }
                })
        }, []);

        function setVideo(video) {
            setVideoActive(video);
        }

        const view = (<div className="videos-container">
            <div className="videos-header">
                <div className="wrapper">
                    <div></div>
                    <div className="page-name"><div>VIDEOS</div></div>
                </div>
            </div>
            <div className="videos-body">
                <div className="wrapper">
                    <div className="player">
                        {activeVideo.description2 ?
                            <iframe className="vembed" src={activeVideo.description2.replace('watch?v=', 'embed').replace('youtu.be', 'youtube.com/embed')} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : ''}
                    </div>
                    <div className="video-list">
                        {
                            videos.map(item => <div className={'item ' + (item.id === activeVideo.id ? 'active' : '')} onClick={() => setVideo(item)}><div className="title">{item.title}</div><div className="details">{item.description}</div></div>)
                        }
                    </div>
                </div>
            </div>
        </div>);
        return view;
    }
};

export default Videos;