import React, { useEffect, useState } from 'react';
import './Facilities.scss';

import Img1 from '../assets/facilities/COURT-AND-RELATED-AREAS-756x1024.jpg';
import Img2 from '../assets/facilities/INF-REVISED-P13-COURT-DIAGRAMS-719x1024.jpg';
import Img3 from '../assets/facilities/Goalposts_no_heading.jpg';
import Img4 from '../assets/facilities/INF_2016_netball_ball.jpg';
import Img5 from '../assets/facilities/INF_2016_netball_players_1.jpg';
import Img6 from '../assets/facilities/INF_2016_netball_players_2.jpg';

const Facilities = {
    Index: () => {

        const view = <div className="facilities-container">
            <div className="wrapper">

                <div className="page-header">
                    <div className="page-title">Court and related areas</div>
                </div>
                <div className="page-body">
                    <div className="page-body-content">
                        <p>The court is rectangular in shape and is level and firm.The surface should be wooden (preferably sprung wooden) but may consist of other material provided it is safe to play on.</p>

                        <p>The two longer sides are called side lines and measure 30.5 m (100 ft).</p>

                        <p>The two shorter sides are called goal lines and measure 15.25 m (50 ft).</p>

                        <p>Two lines parallel to the goal lines divide the court into three equal areas. These lines are called transverse lines. The middle area is called the centre third and the two end areas are the goal thirds.</p>

                        <p>A circle 0.9 m(3 ft) in diameter is located in the centre of the court.This is called the centre circle.</p >

                        <p>A goal circle is located at each end of the court.This is a semi - circle of radius 4.9 m(16 ft) whose centre is the mid - point of the outside of the goal line.</p >

                        <p>All lines(preferably white) are 50 mm(2 in) wide and are part of the court area they outline.</p >

                        <div className="sub-text">Court Surround</div>

                        < p >The court surround is rectangular in its outer shape and it surrounds the court.The distance between the edge of the court surround and the goal lines and side lines is 3.05 m(10 ft).</p >

                        <div className="sub-text">Field of Play</div>

                        <p>The field of play is rectangular in shape and consists of the court and the court surround.During play only on - court players and umpires are permitted in the field of play.</p >


                        <div className="sub-text">Playing Enclosure</div>
                        <p>A bench zone is located immediately adjacent to the field of play. The official bench, umpires’ bench and team benches are all located on one side of the court in the bench zone.</p>

                        <p>The playing enclosure consists of the field of play and the bench zone. Entry to the playing enclosure during a match is limited to those persons with official event accreditation.</p>

                        <p>If desired, an equivalent zone on the opposite side of the court may also be included in the playing enclosure. This zone is to be used by media and other technical officials as needed.</p>
                    </div>
                    <div className="thumb">
                        <div>
                            <img src={Img1} alt="court" loading="lazy" />
                            <img src={Img2} alt="court" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="page-header">
                    <div className="page-title">Goalposts</div>
                </div>
                <div className="page-body">
                    <div className="page-body-content">
                        <p>A goalpost is placed at the mid-point of each goal line. It consists of the following:</p>

                        <p>A vertical metal pole 65-100 mm (2.5-4 in) in diameter and 3.05 m (10 ft) high. The pole is:</p>

                        <p>Inserted in the ground or sleeved beneath the floor so when it is knocked there is a minimal amount of movement and it remains stable</p>

                        <p>Placed so the back of the pole is at the outside edge of the goal line</p>

                        <p>Covered with padding of uniform thickness not more than 50 mm(2 in) thick and extending the full length of the pole</p >

                        <p>A horizontal metal ring made of steel rod 15 mm(5 / 8 in) in diameter with an internal diameter of 380 mm(15 in)</p >

                        <p>A horizontal metal bar of length 150 mm(6 in), projecting from the front edge at the top of the pole, to which the ring is attached</p >

                        <p>A net(preferably white) fitted to the ring, clearly visible and open at top and bottom.</p >
                    </div>
                    <div className="thumb">
                        <img src={Img3} alt="court" loading="lazy" />
                    </div>
                </div>
                <div className="page-header">
                    <div className="page-title">Ball</div>
                </div>
                <div className="page-body">
                    <div className="page-body-content">
                        <p>The match ball is spherical in shape:</p>

                        <p>Measures 690-710 mm (27-28 in) in circumference and weighs 400-450 g (14-16 oz)</p>

                        <p>Is made of leather, rubber or suitable synthetic material</p>

                        <p>Is inflated to a pressure of 55 - 69kPa(8 - 10psi).</p >

                        <p>The same match ball is used throughout a match.A spare ball must be at the official bench and the umpire may order its use in the event of damage to the match ball or blood on the ball.</p >

                        <p>The umpires check all match balls before play starts.</p >
                    </div>
                    <div className="thumb">
                        <img src={Img4} alt="court" loading="lazy" />
                    </div>
                </div>
                <div className="page-header">
                    <div className="page-title">Players</div>
                </div>
                <div className="page-body">
                    <div >
                        <p>During a match players must wear:</p>

                        <p>Registered playing uniform and suitable sports footwear (spiked soles are not allowed)</p>

                        <p>Playing position initials 150 mm (6 in) high which must be clearly visible and worn above the waist, front and back.</p>

                        <p>The playing positions and initials are:</p >

                        <p>Goal Shooter(GS), Goal Attack(GA), Wing Attack(WA), Centre(C), Wing Defence(WD),</p >

                        <p>Goal Defence(GD) and Goal Keeper(GK).</p >

                        <p>All players have specified areas of the court where they are allowed to play.</p >

                        <p>These are:</p >
                    </div>
                    <div className="split-img">
                        <img src={Img5} alt="court" loading="lazy" />
                        <img src={Img6} alt="court" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>;

        return view;
    }
}


export default Facilities;