import React, { useEffect } from 'react';
import {
    TransitionGroup,
    SwitchTransition,
    CSSTransition
} from "react-transition-group";
import {
    Switch,
    Route,
    useLocation,
    Redirect
} from "react-router-dom";

import { useStore } from './utils/Store';
import Home from './home/Home';
import EventsPage from './events/EventsPage';
import About from './about/About';
import Videos from './videos/VIdeos';
import Games from './games/GamesPage';
import Facilities from './facilities/Facilities';
// import CartRoutes from './Cart/Routes';
// import ShopRoutes from './Shop/Router';
// import UserRoutes from './User/Router';
// import page404 from './Layout/page404';


const routesList = [
    {
        path: "/",
        exact: true,
        component: Home.Index
    },
    {
        path: '/events',
        exact: true,
        component: EventsPage.Index
    },
    {
        path: '/about',
        exact: true,
        component: About.Index
    },
    {
        path: '/games',
        exact: true,
        component: Games.Index
    },
    {
        path: '/videos',
        exact: true,
        component: Videos.Index
    },
    {
        path: '/facilities',
        exact: true,
        component: Facilities.Index
    }];

export function RouterPath({ children, authGuard, ...rest }) {
    const [store] = useStore();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !authGuard || store.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/user/verify",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export const Routes = () => {
    const location = useLocation();
    const [store, setStore] = useStore();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (store.showMobileMenu) {
            setTimeout(() => { setStore({ ...store, showMobileMenu: false }); }, 100);
        }
    }, [location.pathname]);

    return (
        // <SwitchTransition>
        //     <CSSTransition
        //         key={location.key}
        //         classNames="fade"
        //         timeout={500}
        //     >
        <Switch location={location}>
            {routesList.map((route, index) => (
                <RouterPath
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.component />}
                    authGuard={route.authGuard}
                />

            ))}
            <Route path="*">
                {/* <page404 /> */}
            </Route>
        </Switch>
        /* </CSSTransition>
    </SwitchTransition> */
    );
}

export default { RouterPath, Routes };